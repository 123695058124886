<template>
  <div class="copy-box">
    <a v-if="minio" :href="minio + label" target="_blank" class="copy-link">{{ label }}</a>
    <div class="text" :style="{ '-webkit-line-clamp': fit ? 4 : 3 }" v-else>
      <!-- <p v-if="qr" style="cursor: pointer;" @click="clickHandler(label)">{{ label }}</p> -->
      <p :style="{ cursor: qr ? 'pointer' : 'default' }" @click="clickHandler(label)">{{ label }}</p>
      <p v-if="fit" v-html="getFittingSerialNumber(fit)"></p>
    </div>
    <el-icon class="jz-copy" v-show="label" @click="copy.copyFun(label + (fit ? '-' + fit : ''))"
      ><DocumentCopy
    /></el-icon>
    <!-- <el-icon
      ><a class="el-icon-document-copy jz-copy" @click="copy.copyFun(label + (fit ? '-' + fit : ''))" v-if="label"></a
    ></el-icon> -->
  </div>
</template>
<script setup>
import copy from '../lin/util/common'
import { DocumentCopy } from '@element-plus/icons-vue'
// import { showImg } from '../component/dialog/index.js'

const props = defineProps({
  label: String,
  fit: String,
  minio: String,
  qr: {
    type: Boolean,
    default: false,
  },
})
function getFittingSerialNumber(str) {
  if (str != null && str != '' && str != 'null') {
    return str.replaceAll('/', '<br/>')
  }
}
const clickHandler = url => {
  // if (props.qr) {
  //   const close = showImg(url, () => close())
  // }
}
</script>
<style lang="scss" scoped>
.copy-link {
  color: blue;
  text-decoration: underline;
}
.copy-box {
  display: flex;
}
.jz-copy {
  font-size: 12px;
  margin-left: 5px;
  color: gray;
  margin-top: 5px;
  cursor: pointer;
  // opacity: 0;
  // font-size: 10px;
  // margin-left: 10px;
  // color: gray;
  // margin-top: 10px;
}
//el-descriptions__cell el-descriptions__content is-bordered-content
.jz-copy {
  opacity: 0;
}
.copy-box:hover .jz-copy {
  opacity: 1;
}
</style>
